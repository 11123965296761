import React from 'react'
import classNames from 'classnames'

import './SlantedContainer.scss'

const SlantedContainer = ({isCondensed=false}) => (
    <div className="agnoris-blog-slant-container">
        <div className={classNames("agnoris-blog-background", { "condensed": isCondensed })}>
            <div className="agnoris-blog-background-image"></div>
        </div>
        <div className="agnoris-blog-slant">
        </div>      
    </div>
)

export default SlantedContainer;