import React from 'react';
import LargeLogoImg from './images/agnoris_logo.png';
import MediumLogoImg from './images/agnoris_logo_small.png';

const Logo = function(props){
    let imgSrc = MediumLogoImg;
    
    if (props.type === 'large') {
        imgSrc = LargeLogoImg;
    }
    return <img className={`logo logo-${props.type}`} 
                src={imgSrc} 
                width={props.width? props.width : ''} 
                height={props.height} 
                alt="Agnoris logo"/> 
}

export default Logo;