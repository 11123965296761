import React from 'react'
import Img from "gatsby-image"

import './PostHeader.scss'

const PostHeader = ({post}) => {
    let {frontmatter} = post;
    let {author, date} = frontmatter;
    return(
    <div className="agnoris-blog-post-header-metadata">
        <div>
            {/* <img src={Author} alt="Author profile"/> */}
            {author && 
                <Img
                alt={`Avatar of ${author.id}`}
                fixed={author.avatar.children[0].fixed}
                Tag="span"
                />
            }
        </div>
        <div className="agnoris-blog-post-header-name">{author && author.name}</div>
        <div className="agnoris-blog-post-header-date">{date}</div>
    </div>    
    )
}

export default PostHeader;