/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "typeface-montserrat"

import Header from "./header"
import Footer from "./footer"
import {SlantedContainer} from "./slantedContainer"

import "./layout.scss"

const Layout = ({children, condensedHeader}) => (
  <>
    <SlantedContainer isCondensed={condensedHeader} />
    <Header />
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1080,
        padding: `0px 1.0875rem 1.45rem`,
        paddingTop: 0,
      }}
    >
      <main>{children}</main>
    </div>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
