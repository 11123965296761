import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Button.scss";


export const styles = {
    STYLE_HOLLO: 'hollo',
    STYLE_NORMAL: 'normal',
    STYLE_SOLID: 'solid',
    SIZE_MEDIUM: 'medium',
    SIZE_LARGE: 'large',
    SIZE_SMALL: 'small'
}

const AgnorisButton = ({id, size, action, className, styleType, text, disabled=false, overrideStyles=false, loading, ...rest}) => {
    function buttonStyle() {
        if(overrideStyles) { return ''; }

        return `agnoris-button-${styleType}`;
    }

    return (
        <button
            type={rest.type} 
            id={id}
            onClick={action} 
            disabled={disabled}
            className={classNames("agnoris-button", className, buttonStyle())} {...rest}>
                <span className="button-text">{loading ? "Loading..." : text}</span>
        </button>
    ) 
}

AgnorisButton.defaultProps = {
    styleType: styles.STYLE_NORMAL,
    size: styles.SIZE_MEDIUM,
    type: 'button',
    text: ''
}

AgnorisButton.propTypes = {
    styleType: PropTypes.oneOf([
        styles.STYLE_HOLLO,
        styles.STYLE_NORMAL,
        styles.STYLE_SOLID
    ])
}

export default AgnorisButton;