import React from 'react';
import {Logo} from '../logo';
import './Footer.styles.scss';
import {FaRegEnvelope} from 'react-icons/fa';

const Footer = () => (
    <section id="agnoris-home-footer" className="flex flex-col">
        
        <div id="brand" className="flex">
            <div className="brand-tagline">
                <Logo/>
                <div className="agnoris-home-footer-separator"></div>
                <span id="tagline" className="faded-text xs-text">Made with love in NYC</span>
            </div>
            <div className="contact-box right">
                <FaRegEnvelope size={18} className="contact-envelope" /> 
                <div className="contact-box-details">
                    <div>Have questions? Ask away:</div>
                    <a href="mailto:hi@agnoris.com" className="agnoris-link-base primary-color">hi@agnoris.com</a>
                </div>
            </div>
        </div>

    
        <div id="legal" className="faded-text">
            {/* <Link to={'/terms'} className="agnoris-link-base" >Terms of Service</Link> */}
            {/* <Link to={'/privacy'} className="agnoris-link-base">Privacy Policy</Link> */}
            <span>© {new Date().getFullYear()} Copyright Agnoris
            </span>
        </div>
    </section>
);

export default Footer;