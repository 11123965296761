import React from "react"
import {Link} from "gatsby"

import {AgnorisButton} from '../button'
import Logo from "../../images/agnoris-logo-small.png"

import "./header.scss"

const Header = () => {
  function goToLogin() {
    window.location = "https://www.agnoris.com/login"
  }
  return (
    <header className="agnoris-blog-header">
      <h1 style={{ margin: 0, flexGrow: 1 }}>
        <Link to="/">
            <img src={Logo} alt="Agnoris Logo" style={{width: `150px`, height: `100%`}}/> <span className="agnoris-blog-header-subheader">Blog</span>
        </Link>
      </h1>

      <div className="agnoris-blog-header-navigation">
        <Link to="/page-2/">Home</Link>
        <Link to="/page-2/">About</Link>
        <AgnorisButton text="Login" styleType="hollo" onClick={goToLogin}/>
      </div>
    </header>
  )
}


export default Header